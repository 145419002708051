import * as Yup from 'yup';

import {YupFile} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  file: new YupFile()
    .acceptedFileTypes(['PDF'], messages.BAD_FILE_FORMAT)
    .maxFileSize(25000000, messages.MAX_FILE_SIZE)
    .schema()
    .required(messages.REQUIRED)
});