import {Button, Card, CardBody, CardHeader} from 'reactstrap';

import {Exemption} from '../../types';

type Props = {
  exemption: Exemption
  onDownload: () => void
}

const ScansTab = ({
                    exemption,
                    onDownload
                  }: Props) => {
  return (
    <Card>
      <CardHeader>Available Scans</CardHeader>
      <CardBody>
        {exemption.hasScan ?
          <div>
            <Button color="link"
                    onClick={() => onDownload()}>
              {exemption.reasonId}.pdf
            </Button>
          </div>
          :
          <h6>No scan found.</h6>
        }
      </CardBody>
    </Card>
  );
};

export default ScansTab;