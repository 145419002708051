import * as Yup from 'yup';

import * as messages from '../messages';
import addressSchema from './addressSchema';

export default Yup.object({
  address: addressSchema,
  companyName: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  suffix: Yup.string().nullable(),
  coFirstName: Yup.string().nullable(),
  coLastName: Yup.string().nullable(),
  coMiddleName: Yup.string().nullable(),
  coSuffix: Yup.string().nullable(),
  careOf: Yup.string().nullable(),
  trustFlag: Yup.string()
    .required(messages.REQUIRED)
    .oneOf(['1', '2', '3', '9', '11'], messages.OWNER_FLAG_ERROR)
});