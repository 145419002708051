import {useState} from 'react';
import {Card, Table} from 'reactstrap';

import {ButtonIcon, ConfirmationModal} from '@reasoncorp/kyber-js';

import {formatDate} from '../../util';
import {AuditHistory, Denial} from '../../types';

const leadingHeaders = [
  'Updated DT',
  'User',
  'Update Type',
  'Caller Name',
  'Verdict',
  'Major Reason',
  'Minor Reason',
  'Mgr Rev',
  'Send Ltr',
  'Updated Mail'
];

const yearHeaders = (startYear: number) => {
  return [
    startYear - 3,
    startYear - 2,
    startYear - 1,
    startYear
  ];
};

const trailingHeaders = [
  'Bonafide'
];

type Props = {
  year: number
  deletable: boolean
  history: AuditHistory
  onDelete: (id: number) => void
}

const HistoryTable = ({
                        history,
                        deletable,
                        onDelete,
                        year
                      }: Props) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);

  const handleDelete = (id: number) => {
    onDelete(id);
    toggleDeleteModal();
  };

  const denials = history.denials || [];

  const denyAllow = (denial: Denial) => {
    if (!denial || (history.auditType !== 'No Questionnaire Returned' && history.auditType !== 'Questionnaire Received')) {
      return '';
    }

    const {percentAllowed, denied} = denial;
    return percentAllowed !== 100 ? `${denied ? 'Denied' : 'Valid'} ${percentAllowed}` : 'Valid';
  };

  const yesNo = (item: boolean) => item ? 'YES' : '';

  const headers = [
    ...leadingHeaders,
    ...yearHeaders(year),
    ...trailingHeaders
  ];

  return (
    <>
      <Card className="mb-3">
        <Table bordered responsive size="sm">
          <thead>
            <tr className="bg-light text-white">
              {headers.map((header, i) => <th className="text-center" key={i}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>{formatDate(history.createdAt)}</td>
              <td>{history.username}</td>
              <td>{history.auditType}</td>
              <td>{history.callerName}</td>
              <td>{history.verdict !== 'NO DECISION' && history.verdict}</td>
              <td>{history.majorReason}</td>
              <td>{history.minorReason}</td>
              <td>{yesNo(history.managerReview)}</td>
              <td>{yesNo(history.sendLetter)}</td>
              <td>{yesNo(history.mailingAddressChanged)}</td>
              <td>{denyAllow(denials?.[0])}</td>
              <td>{denyAllow(denials?.[1])}</td>
              <td>{denyAllow(denials?.[2])}</td>
              <td>{denyAllow(denials?.[3])}</td>
              <td>{yesNo(history.bonaFide)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={headers.length}>
                <div className="d-flex justify-content-between align-items-center px-1">
                  <span>Comments: {history.comments}</span>
                  {deletable &&
                  <ButtonIcon icon="trash"
                              className="text-danger"
                              title={`Delete history ${history.auditType} by ${history.username}`}
                              ariaLabel={`Delete history ${history.auditType} by ${history.username}`}
                              onClick={() => toggleDeleteModal()}/>
                  }
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Card>
      <ConfirmationModal isOpen={deleteModalIsOpen}
                         size="lg"
                         title="Delete Audit History"
                         aria-modal={true}
                         confirmButtonText="Yes"
                         cancelButtonText="Cancel"
                         confirmCallback={() => handleDelete(history.id)}
                         cancelCallback={toggleDeleteModal}>
        Are you sure you want to delete this audit history?
      </ConfirmationModal>
    </>
  );
};

export default HistoryTable;
