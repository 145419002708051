import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider, ProSuiteJurisdiction, ssoUtils, User, UserProvider} from '@reasoncorp/kyber-js';

import './index.scss';
import App from './App';
import config from './config';
import {ssoApi} from './api';
import {AuditProAppProvider, DriversLicenseProvider} from './contexts';
import * as messages from './messages';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${messages.APP_TITLE}`;
} else {
  document.title = messages.APP_TITLE;
}

const setPermissions = (currentUser?: User) => ({
  isAdmin: currentUser ? ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.AUDITPRO_APP) : false
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <AlertsProvider>
      <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
        <AuditProAppProvider>
          <DriversLicenseProvider>
            <App/>
          </DriversLicenseProvider>
        </AuditProAppProvider>
      </UserProvider>
    </AlertsProvider>
  </BrowserRouter>
);