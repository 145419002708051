import {ReactNode, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {FormikValues, useFormikContext} from 'formik';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

type Props = {
  children: ReactNode
  headerText: string
  errorMessage?: string
  submitDisabled?: boolean
  resetDisabled?: boolean
  onReset: () => void
}

const SearchCard = ({
                      children,
                      errorMessage,
                      headerText,
                      submitDisabled,
                      resetDisabled,
                      onReset
                    }: Props) => {
  const formikContext = useFormikContext<FormikValues>();
  const submitDisabledToUse = submitDisabled !== undefined ?
    submitDisabled :
    !formikContext.dirty || !formikContext.isValid || formikContext.isSubmitting;
  const resetDisabledToUse = resetDisabled !== undefined ?
    resetDisabled :
    !formikContext.dirty || formikContext.isSubmitting;
  const [resetModalIsOpen, setResetModalIsOpen] = useState(false);

  const toggleResetModal = () => setResetModalIsOpen(!resetModalIsOpen);

  const handleReset = () => {
    onReset();
    formikContext.resetForm();
    toggleResetModal();
  };

  return (
    <>
      <Card>
        <Form onSubmit={formikContext.handleSubmit}>
          <CardHeader>{headerText}</CardHeader>
          <CardBody>
            {children}
            <Row role="search">
              <Col md={5} className="text-danger font-weight-bold">
                {errorMessage}
              </Col>
              <Col md={7} className="d-flex justify-content-end">
                <Button color="success"
                        size="sm"
                        className="mr-2"
                        disabled={submitDisabledToUse}
                        type="submit">
                  Search
                </Button>
                <Button color="danger"
                        size="sm"
                        disabled={resetDisabledToUse}
                        onClick={toggleResetModal}
                        onKeyDown={toggleResetModal}>
                  Reset
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Form>
      </Card>
      <ConfirmationModal isOpen={resetModalIsOpen}
                         size="lg"
                         title="Reset Search"
                         aria-modal={true}
                         confirmButtonText="Yes"
                         cancelButtonText="Cancel"
                         confirmCallback={handleReset}
                         cancelCallback={toggleResetModal}>
        Are you sure you want to reset all of the fields?
      </ConfirmationModal>
    </>
  );
};

export default SearchCard;
