import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {Owner, UncleanRecord} from '../types';
import {ownerApi} from '../api';
import * as messages from '../messages';
import {OwnerCard} from '../components/shared';
import {useAuditProAppContext} from '../hooks';

const CleanOwner = () => {
  const {countyId, localUnit} = useParams() as {countyId: string, localUnit: string};
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {configuration} = useAuditProAppContext();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const [uncleanRecords, setUncleanRecords] = useState<UncleanRecord[]>([]);
  const [owner, setOwner] = useState<Owner | undefined>(undefined);

  const county = useMemo(() => {
    return configuration.counties.filter(county => county.id === Number(countyId))?.[0];
  }, [
    configuration,
    countyId
  ]);

  const breadcrumbs = useMemo(() => ([
    {text: 'Clean', route: '/clean'},
    {text: county?.displayName, route: `/clean/${countyId}`},
    {text: localUnit, active: true}
  ]), [
    county,
    countyId,
    localUnit
  ]);

  const handleRecordClick = useCallback(async (uncleanRecord: UncleanRecord) => {
    try {
      const owner = await ownerApi.find(uncleanRecord.ownerId);
      setOwner(owner);
    } catch (e) {
      showErrorAlert(messages.OWNER_LOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const loadDirtyOwners = useCallback(async () => {
    try {
      const dirtyRecords = await ownerApi.searchDirty(Number(countyId), localUnit);
      setUncleanRecords(dirtyRecords);
    } catch (error) {
      showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
    }
  }, [
    countyId,
    localUnit,
    showErrorAlert
  ]);

  useEffect(() => {
    const loadDirtyRecords = async () => {
      try {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: true}));
        const dirtyRecords = await ownerApi.searchDirty(Number(countyId), localUnit);
        setUncleanRecords(dirtyRecords);
      } catch (error) {
        showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loadError: true}));
      } finally {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: false}));
      }
    };

    void loadDirtyRecords();
  }, [
    county,
    countyId,
    localUnit,
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    headers: [],
    items: uncleanRecords,
    noResultsMessage: 'No records to clean',
    renderRow: (uncleanRecord: UncleanRecord) => {
      return (
        <tr key={uncleanRecord.reasonId}
            className="cursor-pointer"
            onClick={() => handleRecordClick(uncleanRecord)}>
          <td className="align-left pl-3">{uncleanRecord.reasonId}</td>
        </tr>
      );
    }
  }), [
    uncleanRecords,
    handleRecordClick
  ]);

  const handleClean = useCallback(async (ownerRequest: Owner) => {
    try {
      await ownerApi.update(owner?.id ?? 0, ownerRequest);
      await ownerApi.clean(owner?.id ?? 0);
      setOwner(undefined);
      showSuccessAlert(messages.RECORD_CLEAN_SUCCESS);

      await loadDirtyOwners();
    } catch (e) {
      showErrorAlert(messages.RECORD_CLEAN_FAILURE);
    }
  }, [
    showSuccessAlert,
    showErrorAlert,
    loadDirtyOwners,
    owner
  ]);

  const handleSkip = useCallback(async () => {
    try {
      await ownerApi.skip(owner?.id ?? 0);
      setOwner(undefined);
      showSuccessAlert(messages.RECORD_SKIP_SUCCESS);

      await loadDirtyOwners();
    } catch (e) {
      showErrorAlert(messages.RECORD_SKIP_FAILURE);
    }
  }, [
    showSuccessAlert,
    showErrorAlert,
    loadDirtyOwners,
    owner
  ]);

  const handleSave = useCallback(async (ownerRequest: Owner) => {
    try {
      await ownerApi.update(owner?.id ?? 0, ownerRequest);
      showSuccessAlert(messages.OWNER_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.OWNER_SAVE_FAILURE);
    }
  }, [
    showSuccessAlert,
    showErrorAlert,
    owner
  ]);

  const handleCancel = useCallback(() => setOwner(undefined), []);

  return (
    <Container fluid className="UncleanOwners">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
        <>
          <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
          <Row>
            <Col md="4" className="clean-nav-col">
              <Card>
                <CardHeader>{county?.displayName}: {localUnit}</CardHeader>
                <div className="clean-card-scroll">
                  <CustomTable {...tableProps}/>
                </div>
              </Card>
            </Col>
            <Col>
              {owner && <OwnerCard onCancel={handleCancel}
                                   onUpdate={handleSave}
                                   owner={owner}
                                   isEditing={true}
                                   showCleanButtons={true}
                                   onClean={handleClean}
                                   onSkip={handleSkip}/>}
            </Col>
          </Row>
        </>
      }
    </Container>
  );
};

export default CleanOwner;