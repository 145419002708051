import * as Yup from 'yup';
import {ArraySchema, StringSchema} from 'yup';

import * as messages from '../messages';
import {AuditActivityCode} from '../enums';

export default Yup.object().shape({
  activityCode: Yup.number().required(messages.REQUIRED),
  callAt: Yup.date().required(messages.REQUIRED),
  callerName: Yup.string()
    .when('activityCode', (activityCode: AuditActivityCode, schema: StringSchema) => {
      if (activityCode === AuditActivityCode.TAXPAYER_CALL) {
        return schema.required(messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  comments: Yup.string()
    .when('activityCode', (activityCode: AuditActivityCode, schema: StringSchema) => {
      if (activityCode === AuditActivityCode.TAXPAYER_CALL ||
        activityCode === AuditActivityCode.COMMENTS ||
        activityCode === AuditActivityCode.QUESTIONNAIRE_RECEIVED ||
        activityCode === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED
      ) {
        return schema.required(messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  denyValid: Yup.string()
    .when('activityCode', (activityCode: AuditActivityCode, schema: StringSchema) => {
      if (activityCode === AuditActivityCode.QUESTIONNAIRE_RECEIVED ||
        activityCode === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED) {
        return schema.required(messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  majorReasonId: Yup.string()
    .when('activityCode', (activityCode: AuditActivityCode, schema: StringSchema) => {
      if (activityCode === AuditActivityCode.QUESTIONNAIRE_RECEIVED ||
        activityCode === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED) {
        return schema.required(messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  minorReasonId: Yup.string()
    .when('activityCode', (activityCode: AuditActivityCode, schema: StringSchema) => {
      if (activityCode === AuditActivityCode.QUESTIONNAIRE_RECEIVED ||
        activityCode === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED) {
        return schema.required(messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  denials: Yup.array()
    .when('denyValid', (denyValid: string, schema: ArraySchema<any>) => {
      if (denyValid === '1') {
        return schema.of(
          Yup.object().shape({
            year: Yup.number().required(messages.REQUIRED),
            denied: Yup.bool().required(messages.REQUIRED),
            percentAllowed: Yup.number().required(messages.REQUIRED)
              .min(0, messages.PRE_PERCENT_RANGE_ERROR)
              .max(100, messages.PRE_PERCENT_RANGE_ERROR)
              .typeError(messages.REQUIRED)
          }))
          .min(4, messages.REQUIRED);
      } else {
        return schema;
      }
    }),
  mailingAddressChanged: Yup.bool(),
  nonResident: Yup.bool()
    .nullable(),
  sendLetter: Yup.bool(),
  managerReview: Yup.bool(),
  bonaFide: Yup.bool()
});