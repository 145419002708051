import {FormikSelect, UsState} from '@reasoncorp/kyber-js';

type Props = {
  name: string
}

const StatesSelect = ({name}: Props) => {

  return (
    <FormikSelect name={name}
                  labelText="State">
      <option value="">Select</option>
      {Object.keys(UsState).map((state) => {
        return <option value={state} key={state}>{state}</option>;
      })}
    </FormikSelect>
  );
};

export default StatesSelect;
