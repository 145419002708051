import {useCallback, useContext, useState} from 'react';
import {Card, CardHeader, Col, Container, Jumbotron, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {AlertsContext, County, FormikInput, FormikNumberInput, ProgressIndicator} from '@reasoncorp/kyber-js';

import {CountySelect, SearchCard, StatesSelect} from '../components/shared';
import {exemptionApi} from '../api';
import * as messages from '../messages';
import {dashboardSearchSchema} from '../schema';
import {ExemptionLite} from '../types';
import {DashboardFormFields} from '../types/form';
import {ExemptionSearchRequest} from '../types/request';
import {useAuditProAppContext} from '../hooks';
import {ExemptionResultsTable} from '../components/exemption';

type QueryValuesProps = {
  [field: string]: any
  counties: County[]
}

const QueryValues = ({
                       query,
                       counties
                     }: QueryValuesProps) => {
  return (
    <Jumbotron className="p-2">
      <Row>
        {Object.keys(query).map((queryKey, i) => {
          const value = Array.isArray(query[queryKey]) ? query[queryKey].join(', ') : query[queryKey];
          // Null check is for number fields

          if (queryKey === 'propertyCountyId') {
            const county = counties.filter(county => county.id === Number(query[queryKey]))?.[0];

            return county ? <Col md={4} lg={3} key={i}>
              <span>County: <b>{county.displayName}</b></span>
            </Col> : null;
          } else if (value !== '' && value !== null) {
            return (
              <Col md={4} lg={3} key={i}>
                <span>{queryKey}: <b>{value}</b></span>
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </Jumbotron>
  );
};

const Dashboard = () => {
  const {showErrorAlert} = useContext(AlertsContext);
  const {configuration} = useAuditProAppContext();
  const [exemptions, setExemptions] = useState<ExemptionLite[]>([]);
  const [searchState, setSearchState] = useState({searchPerformed: false, searching: false});

  const initialValues: DashboardFormFields = {
    reasonId: '',
    parcelNumber: '',
    propertyCountyId: null,
    propertyLocalUnit: '',
    ownerCompanyName: '',
    ownerFirstName: '',
    ownerLastName: '',
    coOwnerFirstName: '',
    coOwnerLastName: '',
    mailingStreetNumber: '',
    mailingStreetName: '',
    mailingCity: '',
    mailingState: '',
    propertyStreetNumber: '',
    propertyStreetName: '',
    propertyCity: '',
    prePercent: ''
  };

  const handleSearch = useCallback(async (dashboardFormFields: DashboardFormFields,
                                          formikHelpers: FormikHelpers<DashboardFormFields>) => {
    try {
      setSearchState(searchState => ({...searchState, searching: true}));
      const exemptionSearchRequest: ExemptionSearchRequest = {
        years: configuration.currentYears,
        reasonId: dashboardFormFields.reasonId.trim(),
        parcelNumber: dashboardFormFields.parcelNumber.trim(),
        propertyCountyId: dashboardFormFields.propertyCountyId,
        propertyLocalUnit: dashboardFormFields.propertyLocalUnit.trim(),
        ownerCompanyName: dashboardFormFields.ownerCompanyName.trim(),
        ownerFirstName: dashboardFormFields.ownerFirstName.trim(),
        ownerLastName: dashboardFormFields.ownerLastName.trim(),
        coOwnerFirstName: dashboardFormFields.coOwnerFirstName.trim(),
        coOwnerLastName: dashboardFormFields.coOwnerLastName.trim(),
        mailingStreetNumber: dashboardFormFields.mailingStreetNumber.trim(),
        mailingStreetName: dashboardFormFields.mailingStreetName.trim(),
        mailingCity: dashboardFormFields.mailingCity.trim(),
        mailingState: dashboardFormFields.mailingState.trim(),
        propertyStreetNumber: dashboardFormFields.propertyStreetNumber.trim(),
        propertyStreetName: dashboardFormFields.propertyStreetName.trim(),
        propertyCity: dashboardFormFields.propertyCity.trim(),
        prePercent: dashboardFormFields.prePercent.length !== 0 ? Number(dashboardFormFields.prePercent) : null
      };
      formikHelpers.setValues({
        ...exemptionSearchRequest,
        reasonId: dashboardFormFields.reasonId.trim(),
        prePercent: dashboardFormFields.prePercent
      });
      const exemptionResults = await exemptionApi.search(exemptionSearchRequest);
      setExemptions(exemptionResults);
      setSearchState({searchPerformed: true, searching: false});
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setSearchState({searchPerformed: false, searching: false});
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    configuration.currentYears,
    showErrorAlert
  ]);

  const handleReset = useCallback(() => {
    setExemptions([]);
    setSearchState(searchState => ({...searchState, searchPerformed: false}));
  }, []);

  return (
    <Container fluid aria-label="search">
      <Formik initialValues={initialValues}
              validationSchema={dashboardSearchSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSearch}>
        {(formikProps) => (
          <>
            <div className="mb-4">
              <SearchCard headerText="Search"
                          submitDisabled={formikProps.isSubmitting}
                          resetDisabled={formikProps.isSubmitting}
                          onReset={handleReset}>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Reason ID"
                                 name="reasonId"/>
                  </Col>
                  <Col md={2}>
                    <FormikInput labelText="Parcel ID"
                                 name="parcelNumber"/>
                  </Col>
                  <Col md={3}>
                    <CountySelect name="propertyCountyId"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="City/Township"
                                 name="propertyLocalUnit"/>
                  </Col>
                  <Col md={2}>
                    <FormikInput labelText="Owner"
                                 name="ownerCompanyName"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormikInput labelText="Owner First"
                                 name="ownerFirstName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Owner Last"
                                 name="ownerLastName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Co-Owner First"
                                 name="coOwnerFirstName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Co-Owner Last"
                                 name="coOwnerLastName"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Mail Street #"
                                 name="mailingStreetNumber"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Mail Street Name"
                                 name="mailingStreetName"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Mail City"
                                 name="mailingCity"/>
                  </Col>
                  <Col md={2}>
                    <StatesSelect name="mailingState"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Prop Street #"
                                 name="propertyStreetNumber"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Prop Street Name"
                                 name="propertyStreetName"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Prop City"
                                 name="propertyCity"/>
                  </Col>
                  <Col md={2}>
                    <FormikNumberInput labelText="PRE %"
                                       name="prePercent"
                                       maxLength={3}/>
                  </Col>
                </Row>
              </SearchCard>
            </div>
            <QueryValues counties={configuration.counties}
                         query={{
                           years: configuration.currentYears,
                           ...formikProps.values
                         }}/>
          </>
        )}
      </Formik>
      {searchState.searching && <ProgressIndicator/>}
      <Card className={!searchState.searchPerformed || searchState.searching ? 'd-none' : 'mt-3'}>
        <CardHeader>Search Results</CardHeader>
        <ExemptionResultsTable exemptions={exemptions}/>
      </Card>
    </Container>
  );
};

export default Dashboard;
