import {createContext} from 'react';

import {AuditProAppContextType, AuditProConfiguration} from '../types';

export default createContext<AuditProAppContextType>({
  configuration: {
    currentYears: [],
    reportYears: [],
    defaultReportYear: new Date().getFullYear(),
    counties: []
  },
  setConfiguration: (configuration: AuditProConfiguration) => configuration
});