import * as Yup from 'yup';

export default Yup.object({
  streetNumber: Yup.string().nullable(),
  streetName: Yup.string().nullable(),
  streetType: Yup.string().nullable(),
  directionPrefix: Yup.string().nullable(),
  directionSuffix: Yup.string().nullable(),
  unit: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zip: Yup.string().nullable()
});