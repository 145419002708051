import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {FormikInput, FormikSelect} from '@reasoncorp/kyber-js';

import {ownerSchema} from '../../schema';
import {Owner} from '../../types';
import CleanButtons from './CleanButtons';
import StatesSelect from './StatesSelect';

type Props = {
  onCancel: () => void
  onUpdate: (ownerRequest: Owner) => void
  owner: Owner
  toggleEdit?: () => void
  isEditing: boolean,
  showCleanButtons?: boolean,
  onClean?: (ownerRequest: Owner) => void,
  onSkip?: () => void
}

const OwnerCard = ({
                     isEditing,
                     onCancel,
                     onUpdate,
                     toggleEdit = () => {
                     },
                     owner,
                     showCleanButtons = false,
                     onClean = () => {
                     },
                     onSkip = () => {
                     }
                   }: Props) => {

  const renderCardHeader = (formikProps?: FormikProps<Owner>) => {
    if (showCleanButtons) {
      return (
        <CardHeader>Owner Information</CardHeader>
      );
    } else if (isEditing && formikProps) {
      return (
        <CardHeader>
          <Row>
            <Col className="align-self-center">Owner Information</Col>
            <Col className="justify-content-end d-flex">
              <Button type="submit"
                      size="sm"
                      color="success"
                      className="mr-2"
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Update
              </Button>
              <Button size="sm"
                      color="danger"
                      onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </CardHeader>
      );
    } else {
      return (
        <CardHeader>
          <Row>
            <Col className="align-self-center">Owner Information</Col>
            <Col hidden={showCleanButtons} className="justify-content-end d-flex">
              <Button size="sm"
                      color="primary"
                      onClick={toggleEdit}>
                Edit
              </Button>
            </Col>
          </Row>
        </CardHeader>
      );
    }
  };

  const renderOriginalOwnerInfo = () => {
    return (
      <>
        <Row>
          <Col>
            <strong className="mr-1">Original Owner 1:</strong>
            <span className="text-uppercase">{owner.originalName1}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong className="mr-1">Original Owner 2:</strong>
            <span className="text-uppercase">{owner.originalName2}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong className="mr-1">Original Address:</strong>
            <span className="text-uppercase">{owner.originalAddress}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
      </>
    );
  };

  return isEditing ?
    <Formik initialValues={{...owner}}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={onUpdate}
            validationSchema={ownerSchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Card className="mb-4">
            {renderCardHeader(formikProps)}
            <CardBody>
              {renderOriginalOwnerInfo()}
              <Row>
                <Col sm={9}>
                  <FormikInput labelText="Owner"
                               aria-label="Owner Company Name"
                               name="companyName"
                               maxLength="100"/>
                </Col>
                <Col sm={3}>
                  <FormikSelect name="trustFlag"
                                labelText="Flag">
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="9">9</option>
                    <option value="11">11</option>
                  </FormikSelect>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput labelText="First"
                               aria-label="Owner First Name"
                               name="firstName"
                               maxLength="100"/>
                </Col>
                <Col>
                  <FormikInput labelText="Middle"
                               aria-label="Owner Middle Name"
                               name="middleName"
                               maxLength="20"/>
                </Col>
                <Col>
                  <FormikInput labelText="Last"
                               aria-label="Owner Last Name"
                               name="lastName"
                               maxLength="50"/>
                </Col>
                <Col>
                  <FormikInput labelText="Suffix"
                               aria-label="Owner Suffix"
                               name="suffix"
                               maxLength="20"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput labelText="Co First"
                               aria-label="Co Owner First Name"
                               name="coFirstName"
                               maxLength="50"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Middle"
                               aria-label="Co Owner Middle Name"
                               name="coMiddleName"
                               maxLength="20"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Last"
                               aria-label="Co Owner Last Name"
                               name="coLastName"
                               maxLength="35"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Suffix"
                               aria-label="Co Owner Suffix"
                               name="coSuffix"
                               maxLength="20"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput labelText="3rd Owner"
                               aria-label="3rd Owner Name"
                               name="careOf"
                               maxLength="100"/>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <FormikInput labelText="Street #"
                               aria-label="Owner Street Number"
                               name="address.streetNumber"
                               maxLength="15"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Dir 1"
                               aria-label="Owner Direction Prefix"
                               name="address.directionPrefix"
                               maxLength="15"/>
                </Col>
                <Col sm={4}>
                  <FormikInput labelText="Street Name"
                               aria-label="Owner Street Name"
                               name="address.streetName"
                               maxLength="75"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Type"
                               aria-label="Owner Street Type"
                               name="address.streetType"
                               maxLength="35"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Dir 2"
                               aria-label="Owner Direction Suffix"
                               name="address.directionSuffix"
                               maxLength="15"/>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <FormikInput labelText="Unit"
                               aria-label="Owner Unit"
                               name="address.unit"
                               maxLength="35"/>
                </Col>
                <Col sm={4}>
                  <FormikInput labelText="City"
                               aria-label="Owner City"
                               name="address.city"
                               maxLength="25"/>
                </Col>
                <Col sm={2}>
                  <StatesSelect name="address.state"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Zip"
                               aria-label="Owner Zip Code"
                               name="address.zip"
                               maxLength="11"/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {showCleanButtons &&
            <CleanButtons onClean={() => onClean(formikProps.values)}
                          onSkip={onSkip}
                          onUpdate={formikProps.handleSubmit}
                          onCancel={onCancel}/>
          }
        </Form>)}
    </Formik>
    :
    <Card className="mb-4">
      {renderCardHeader()}
      <CardBody>
        {renderOriginalOwnerInfo()}
        <Row>
          <Col sm={9}>
            <dt>Owner</dt>
            <dd>{owner.companyName}</dd>
          </Col>
          <Col sm={3}>
            <dt>Flag</dt>
            <dd>{owner.trustFlag}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>First</dt>
            <dd>{owner.firstName}</dd>
          </Col>
          <Col>
            <dt>Middle</dt>
            <dd>{owner.middleName}</dd>
          </Col>
          <Col>
            <dt>Last</dt>
            <dd>{owner.lastName}</dd>
          </Col>
          <Col>
            <dt>Suffix</dt>
            <dd>{owner.suffix}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>Co First</dt>
            <dd>{owner.coFirstName}</dd>
          </Col>
          <Col>
            <dt>Co Middle</dt>
            <dd>{owner.coMiddleName}</dd>
          </Col>
          <Col>
            <dt>Co Last</dt>
            <dd>{owner.coLastName}</dd>
          </Col>
          <Col>
            <dt>Co Suffix</dt>
            <dd>{owner.coSuffix}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>3rd Owner</dt>
            <dd>{owner.careOf}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <dt>Street #</dt>
            <dd>{owner.address.streetNumber}</dd>
          </Col>
          <Col sm={2}>
            <dt>Dir 1</dt>
            <dd>{owner.address.directionPrefix}</dd>
          </Col>
          <Col sm={3}>
            <dt>Street Name</dt>
            <dd>{owner.address.streetName}</dd>
          </Col>
          <Col sm={2}>
            <dt>Type</dt>
            <dd>{owner.address.streetType}</dd>
          </Col>
          <Col sm={2}>
            <dt>Dir 2</dt>
            <dd>{owner.address.directionSuffix}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>Unit</dt>
            <dd>{owner.address.unit}</dd>
          </Col>
          <Col>
            <dt>City</dt>
            <dd>{owner.address.city}</dd>
          </Col>
          <Col>
            <dt>State</dt>
            <dd>{owner.address.state}</dd>
          </Col>
          <Col>
            <dt>Zip</dt>
            <dd>{owner.address.zip}</dd>
          </Col>
        </Row>
      </CardBody>
    </Card>;
};

export default OwnerCard;
