import {memo} from 'react';
import {Col, Jumbotron, Row} from 'reactstrap';

import {formatBoolean, formatDate, openAndFocus} from '../../util';
import {AuditStatus} from '../../types';

type Props = {
  status: AuditStatus
}

const AuditStatusPanel = ({status}: Props) => {

  return (
    <Jumbotron className="p-3 pb-2 pt-2">
      <Row>
        <Col md={3}>
          <strong className="mr-1">Letter Date:</strong>
          <span>{status.letterOn && formatDate(status.letterOn)}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">Questionnaire Received:</strong>
          <span className="text-uppercase">{formatBoolean(status.questionnaireReceived)}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">Major Reason:</strong>
          <span className="text-uppercase">{status.majorReason}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">Final Review:</strong>
          <span className="text-uppercase">{formatBoolean(status.accountResolved)}</span>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <strong className="mr-1">Query Type:</strong>
          <span className="text-uppercase">{status.queryType}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">Recommendation:</strong>
          <span className="text-uppercase">{status.recommendation !== 'No Decision' && status.recommendation}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">Minor Reason:</strong>
          <span className="text-uppercase">{status.minorReason}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1">OST:</strong>
          <span className="text-uppercase">{formatBoolean(status.nonResident)}</span>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <strong className="mr-1">Exported:</strong>
          {status.exported &&
            <a className="text-danger text-uppercase"
               href={`/reports/export/${status.exportYear}/${status.exportedDenialBatchId}`}
               onClick={((e) => openAndFocus(e, `/reports/export/${status.exportYear}/${status.exportedDenialBatchId}`, 'exportView'))}>
              {formatDate(status.exportedDenialCreatedAt)}
            </a>
          }
          {!status.exported && <span className="text-uppercase">No</span>}
        </Col>
      </Row>
    </Jumbotron>
  );
};

export default memo(AuditStatusPanel);