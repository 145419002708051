import {memo, ReactNode} from 'react';

type Props = {
  name: string
  content: ReactNode
  active: boolean
  onClick: (name: string) => void
}

const Tab = ({
               name,
               content,
               active,
               onClick
             }: Props) => {
  return (
    <li role="tab"
        onClick={() => onClick(name)}
        className={active ? 'active bg-primary p-3 cursor-pointer' : 'p-3 cursor-pointer'}>
      {content}
    </li>
  );
};

export default memo(Tab);