import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput} from '@reasoncorp/kyber-js';

import {Property} from '../../types';
import {propertySchema} from '../../schema';
import {StatesSelect} from '../shared';


type Props = {
  onCancel: () => void
  onUpdate: (propertyRequest: Property) => void
  property: Property
  toggleEdit: () => void
  isEditing: boolean
  className?: string
}

const PropertyCard = ({
                        onCancel,
                        onUpdate,
                        toggleEdit,
                        isEditing,
                        property,
                        className
                      }: Props) => {

  return isEditing ?
    <Formik initialValues={property}
            enableReinitialize={true}
            onSubmit={onUpdate}
            validationSchema={propertySchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Card className={className}>
            <CardHeader>
              <Row>
                <Col className="align-self-center">Property Information</Col>
                <Col className="justify-content-end d-flex">
                  <Button type="submit"
                          size="sm"
                          color="success"
                          className="mr-2"
                          disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                    Update
                  </Button>
                  <Button size="sm"
                          color="danger"
                          onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={2}>
                  <FormikInput labelText="Street #"
                               name="address.streetNumber"
                               maxLength="15"/>
                </Col>
                <Col md={2}>
                  <FormikInput labelText="Dir 1"
                               name="address.directionPrefix"
                               ariaLabel="Property Direction Prefix"
                               maxLength="15"/>
                </Col>
                <Col md={4}>
                  <FormikInput labelText="Street Name"
                               name="address.streetName"
                               aria-label="Property Street Name"
                               maxLength="75"/>
                </Col>
                <Col md={2}>
                  <FormikInput labelText="Type"
                               name="address.streetType"
                               aria-label="Property Street Type"
                               maxLength="35"/>
                </Col>
                <Col md={2}>
                  <FormikInput labelText="Dir 2"
                               name="address.directionSuffix"
                               aria-label="Property Direction Suffix"
                               maxLength="15"/>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormikInput labelText="Unit"
                               name="address.unit"
                               aria-label="Property Unit"
                               maxLength="15"/>
                </Col>
                <Col md={4}>
                  <FormikInput labelText="City"
                               name="address.city"
                               aria-label="Property City"
                               maxLength="25"/>
                </Col>
                <Col md={2}>
                  <StatesSelect name="address.state"/>
                </Col>
                <Col md={3}>
                  <FormikInput labelText="Zip"
                               name="address.zip"
                               aria-label="Property Zip"
                               maxLength="11"/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
    :
    <Card className={className}>
      <CardHeader>
        <Row>
          <Col className="align-self-center">Property Information</Col>
          <Col className="justify-content-end d-flex">
            <Button size="sm"
                    color="primary"
                    onClick={toggleEdit}>
              Edit
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={2}>
            <dt>Street #</dt>
            <dd>{property.address.streetNumber}</dd>
          </Col>
          <Col md={2}>
            <dt>Dir 1</dt>
            <dd>{property.address.directionPrefix}</dd>
          </Col>
          <Col md={3}>
            <dt>Street Name</dt>
            <dd>{property.address.streetName}</dd>
          </Col>
          <Col md={3}>
            <dt>Type</dt>
            <dd>{property.address.streetType}</dd>
          </Col>
          <Col md={2}>
            <dt>Dir 2</dt>
            <dd>{property.address.directionSuffix}</dd>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <dt>Unit</dt>
            <dd>{property.address.unit}</dd>
          </Col>
          <Col md={4}>
            <dt>City</dt>
            <dd>{property.address.city}</dd>
          </Col>
          <Col md={2}>
            <dt>State</dt>
            <dd>{property.address.state}</dd>
          </Col>
          <Col md={3}>
            <dt>Zip</dt>
            <dd>{property.address.zip}</dd>
          </Col>
        </Row>
      </CardBody>
    </Card>;
};

export default PropertyCard;
