import * as Yup from 'yup';
import {endOfToday, startOfTomorrow} from 'date-fns';

import * as messages from '../messages';

export default Yup.object({
  letterSentOn: Yup.date()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED)
    .max(endOfToday(), messages.MUST_BE_EARLIER_THAN(startOfTomorrow()))
});