import {auditProApi} from './apiUtils';
import {Exemption, ExemptionLite, Questionnaire} from '../types';
import {ExemptionSearchRequest, QuestionnaireRequest} from '../types/request';


export const search = (exemptionSearchRequest: ExemptionSearchRequest): Promise<ExemptionLite[]> =>
  auditProApi.postWithJsonResponse(
    `/exemptions`,
    {
      body: JSON.stringify(exemptionSearchRequest)
    }
  );

export const find = (id: number): Promise<Exemption> => auditProApi.getWithJsonResponse(
  `/exemptions/${id}`
);

export const createDuplicate = (id: number): Promise<Exemption> => auditProApi.postWithJsonResponse(
  `/exemptions/${id}/duplicate`
);

export const createQuestionnaire = (id: number, questionnaireRequest: QuestionnaireRequest): Promise<Questionnaire> => auditProApi.postWithJsonResponse(
  `/exemptions/${id}/questionnaire`,
  {
    body: JSON.stringify(questionnaireRequest)
  }
);