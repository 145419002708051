import {Col, Row} from 'reactstrap';

import HistoryTable from './HistoryTable';
import {AuditHistory} from '../../types';

type Props = {
  year: number
  deletable: boolean
  history: AuditHistory[]
  onDelete: (id: number) => void
}

const HistoryTab = ({
                      year,
                      deletable,
                      history,
                      onDelete
                    }: Props) => {
  return (
    <Row>
      <Col>
        {history.map(auditHistory =>
          <HistoryTable key={auditHistory.id}
                        history={auditHistory}
                        year={year}
                        deletable={deletable}
                        onDelete={onDelete}/>
        )}
      </Col>
    </Row>
  );
};

export default HistoryTab;
