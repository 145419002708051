import {Card, CardBody, CardHeader, Table} from 'reactstrap';

import {formatDate} from '../../util';
import {Sale} from '../../types';

type Props = {
  sale?: Sale
  className?: string
}

const SaleCard = ({
                    sale,
                    className
                  }: Props) => {
  return (
    <Card className={className}>
      <CardHeader>Sale Information</CardHeader>
      <CardBody>
        <Table responsive size="sm" striped>
          <tbody>
            <tr>
              <th scope="row" className="border-0">Sale Date 1</th>
              <th headers="Sale Date 1" className="border-0 font-weight-normal text-dark">{formatDate(sale?.dateOfSale1)}</th>
              <th scope="row" className="border-0">Sale Date 2</th>
              <th headers="Sale Date 2" className="border-0 font-weight-normal text-dark">{formatDate(sale?.dateOfSale2)}</th>
            </tr>
            <tr>
              <th scope="row">Grantor 1</th>
              <th headers="Grantor 1" className="font-weight-normal text-dark">{sale?.grantor1}</th>
              <th scope="row">Grantor 2</th>
              <th headers="Grantor 2" className="font-weight-normal text-dark">{sale?.grantor2}</th>
            </tr>
            <tr>
              <th scope="row">Grantee 1</th>
              <th headers="Grantee 1" className="font-weight-normal text-dark">{sale?.grantee1}</th>
              <th scope="row">Grantee 2</th>
              <th headers="Grantee 2" className="font-weight-normal text-dark">{sale?.grantee2}</th>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default SaleCard;