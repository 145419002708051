import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, CustomTable, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {ValidExemptionReportDto} from '../../types/report';
import {reportApi} from '../../api';
import * as messages from '../../messages';
import {formatNumber} from '../../util';

const ValidExemptionsReport = () => {
  const {year} = useParams<{year: string}>();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    exportingReport: false
  });
  const [validExemptionReportDtos, setValidExemptionReportDtos] = useState<ValidExemptionReportDto[]>([]);

  useEffect(() => {
    const findValidExemptionReport = async () => {
      try {
        const validExemptionReportDtos = await reportApi.findValidExemptionsReport(Number(year));
        setValidExemptionReportDtos(validExemptionReportDtos);
        setLoadingState({loading: false, loadError: false, exportingReport: false});
      } catch (e) {
        showErrorAlert(messages.REPORT_LOAD_FAILURE);
        setLoadingState({loading: false, loadError: true, exportingReport: false});
      }
    };

    void findValidExemptionReport();
  }, [showErrorAlert, year]);

  const tableProps = useMemo(() => ({
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID'},
      {sortKey: 'parcelNumber', title: 'Parcel ID'}
    ],
    paginatorConfig: {
      perPage: 250,
      recordName: 'records',
      allowShowAll: true
    },
    items: validExemptionReportDtos,
    initialSort: {sortKey: 'reasonId', direction: 'asc' as const},
    noResultsMessage: 'No Valid Records available to display in report.',
    renderRow: (validExemptionReportDto: ValidExemptionReportDto) => {
      return <tr>
        <td>
          {validExemptionReportDto.reasonId}
        </td>
        <td>
          {validExemptionReportDto.parcelNumber}
        </td>
      </tr>;
    }
  }), [validExemptionReportDtos]);

  const handleReportDownload = useCallback(async () => {
    try {
      setLoadingState({
        loadError: false,
        loading: false,
        exportingReport: true
      });
      await reportApi.downloadValidExemptionsReport(Number(year));
      showSuccessAlert(messages.REPORT_DOWNLOAD_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_DOWNLOAD_FAILED);
    } finally {
      setLoadingState({
        loadError: false,
        loading: false,
        exportingReport: false
      });
    }
  }, [showErrorAlert, showSuccessAlert, year]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator className="mb-4"/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <Row className="mt-2">
        <Col className="col-6">
          <BreadcrumbsNav inline
                          breadcrumbs={[
                            {text: 'Reports', route: '/reports', icon: 'home' as const},
                            {text: `${year} Valid Records Report`, active: true}
                          ]}/>
        </Col>
        <Col className="col-6 d-flex justify-content-end">
          <Button color="primary"
                  onClick={handleReportDownload}
                  disabled={loadingState.loading || loadingState.loadError}>
            Download Report
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col className="col-6">
                  Valid Records Summary
                </Col>
                <Col className="text-right font-weight-bold">
                  Total records: {formatNumber(validExemptionReportDtos.length)}
                </Col>
              </Row>
            </CardHeader>
            <CustomTable {...tableProps} />
          </Card>
        </Col>
      </Row>
    </>}
    <ProgressModal isOpen={loadingState.exportingReport}
                   title="Generating Valid Records Report"
                   content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
  </Container>;
};

export default ValidExemptionsReport;