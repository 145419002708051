import {format, isValid, parseISO} from 'date-fns';

export const formatDate = (dateValue: Date | string | null | undefined, dateFormat = 'MM/dd/yyyy') => {
  if (dateValue instanceof Date && isValid(dateValue)) {
    return format(dateValue, dateFormat);
  } else {
    const parsedDate = parseISO((dateValue as string));
    return (dateValue != null && isValid(parsedDate)) ? format(parsedDate, dateFormat) : undefined;
  }
};

export const formatDateTime = (dateValue: Date | string | null) => formatDate(dateValue, 'MMM d, yyyy h:mm a');

export const formatNumber = (value: number | null | undefined) => {
  return value?.toLocaleString() ?? '';
};

export const formatBoolean = (value: boolean | null | undefined) => value ? 'Yes' : 'No';