import {PendingExport} from '@reasoncorp/kyber-js';

import {auditProApi} from './apiUtils';

export const findPending = (): Promise<PendingExport[]> => auditProApi.getWithJsonResponse(
  `/exports/pending`
);

export const createBatch = (exemptionIds: number[]): Promise<Response> => auditProApi.postWithEmptyResponse(
  `/exports/batches`,
  {
    body: JSON.stringify({exemptionIds})
  }
);