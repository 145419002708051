import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikSelect} from '@reasoncorp/kyber-js';

import {sendLetterSchema} from '../../schema';
import {isNotNullOrBlank} from '../../util';
import {QueryCode, Questionnaire} from '../../types';
import {QuestionnaireRequest} from '../../types/request';

type Props = {
  hasSecondDenial: boolean
  queryCodes: QueryCode[]
  questionnaire?: Questionnaire
  onSaveDuplicate: () => void
  onSaveQuestionnaire: (questionnaireRequest: QuestionnaireRequest) => void
}

const AdminTab = ({
                    hasSecondDenial,
                    queryCodes,
                    onSaveDuplicate,
                    onSaveQuestionnaire,
                    questionnaire
                  }: Props) => {

  const questionnaireSent = isNotNullOrBlank(questionnaire?.id);
  const initialValues: QuestionnaireRequest = {
    letterOn: questionnaire?.letterOn ?? undefined,
    queryCode: {
      id: questionnaire?.queryCode?.id ?? undefined
    }
  };

  return (
    <Row>
      <Col md={6}>
        <Formik initialValues={initialValues}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={onSaveQuestionnaire}
                validationSchema={sendLetterSchema}>
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Card>
                <CardHeader>Letter Send</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormikSelect name="queryCode.id"
                                    disabled={questionnaireSent}
                                    labelText="Query Type">
                        <option value="">Select</option>
                        {queryCodes
                          .filter(queryCode => questionnaireSent ? true : queryCode.enabled)
                          .map(queryCode =>
                            <option key={queryCode.id} value={queryCode.id}>{queryCode.queryCode}</option>)
                        }
                      </FormikSelect>
                    </Col>
                    <Col>
                      <FormikDatePicker name="letterOn"
                                        labelText="Letter Sent On"
                                        disabled={questionnaireSent}/>
                    </Col>
                    <Col className="align-self-center">
                      <Button color="success"
                              size="sm"
                              onClick={formikProps.submitForm}
                              disabled={questionnaireSent || formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}>
                        Letter Send
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={6}>
        <Card>
          <CardHeader>Second Denial</CardHeader>
          <CardBody>
            <Button disabled={hasSecondDenial}
                    color="success"
                    size="sm"
                    onClick={onSaveDuplicate}>
              Second Denial
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminTab;
