import {useCallback, useContext, useState} from 'react';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {AlertsContext, FormikInput, FormikNumberInput, ProgressIndicator} from '@reasoncorp/kyber-js';

import {exemptionApi} from '../../api';
import * as messages from '../../messages';
import {priorYearSearchSchema} from '../../schema';
import {ExemptionLite} from '../../types';
import {PriorYearsFormFields} from '../../types/form';
import {ExemptionSearchRequest} from '../../types/request';
import {CountySelect, SearchCard, StatesSelect} from '../shared';
import {ExemptionResultsTable} from '../exemption';

const PriorYearsTab = () => {
  const {showErrorAlert} = useContext(AlertsContext);
  const [searchState, setSearchState] = useState({searchPerformed: false, searching: false});
  const [exemptions, setExemptions] = useState<ExemptionLite[]>([]);

  const initialValues: PriorYearsFormFields = {
    parcelNumber: '',
    propertyCountyId: null,
    propertyLocalUnit: '',
    ownerCompanyName: '',
    ownerFirstName: '',
    ownerLastName: '',
    coOwnerFirstName: '',
    coOwnerLastName: '',
    mailingStreetNumber: '',
    mailingStreetName: '',
    mailingCity: '',
    mailingState: '',
    propertyStreetNumber: '',
    propertyStreetName: '',
    propertyCity: '',
    prePercent: '',
    searchErrors: ''
  };

  const handleSearch = useCallback(async (priorYearsFormFields: PriorYearsFormFields,
                              formikHelpers: FormikHelpers<PriorYearsFormFields>) => {
    try {
      setSearchState(searchState => ({...searchState, searching: true}));
      const exemptionSearchRequest: ExemptionSearchRequest = {
        parcelNumber: priorYearsFormFields.parcelNumber.trim(),
        propertyCountyId: priorYearsFormFields.propertyCountyId,
        propertyLocalUnit: priorYearsFormFields.propertyLocalUnit.trim(),
        ownerCompanyName: priorYearsFormFields.ownerCompanyName.trim(),
        ownerFirstName: priorYearsFormFields.ownerFirstName.trim(),
        ownerLastName: priorYearsFormFields.ownerLastName.trim(),
        coOwnerFirstName: priorYearsFormFields.coOwnerFirstName.trim(),
        coOwnerLastName: priorYearsFormFields.coOwnerLastName.trim(),
        mailingStreetNumber: priorYearsFormFields.mailingStreetNumber.trim(),
        mailingStreetName: priorYearsFormFields.mailingStreetName.trim(),
        mailingCity: priorYearsFormFields.mailingCity.trim(),
        mailingState: priorYearsFormFields.mailingState.trim(),
        propertyStreetNumber: priorYearsFormFields.propertyStreetNumber.trim(),
        propertyStreetName: priorYearsFormFields.propertyStreetName.trim(),
        propertyCity: priorYearsFormFields.propertyCity.trim(),
        prePercent: priorYearsFormFields.prePercent.length !== 0 ? Number(priorYearsFormFields.prePercent) : null
      };
      formikHelpers.setValues({...exemptionSearchRequest, prePercent: priorYearsFormFields.prePercent});
      const exemptionResults = await exemptionApi.search(exemptionSearchRequest);
      setExemptions(exemptionResults);
      setSearchState({searchPerformed: true, searching: false});
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setSearchState({searchPerformed: false, searching: false});
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    showErrorAlert
  ]);

  const handleReset = useCallback(() => {
    setExemptions([]);
    setSearchState(searchState => ({...searchState, searchPerformed: false}));
  }, [

  ]);

  return (
    <Formik initialValues={initialValues}
            validateOnMount={true}
            onSubmit={handleSearch}
            validationSchema={priorYearSearchSchema}>
      {(formikProps) => (
        <>
          <SearchCard headerText="Search By"
                      errorMessage={formikProps.errors.searchErrors}
                      onReset={handleReset}>
            <Row>
              <Col md={3}>
                <FormikInput labelText="Parcel ID"
                             name="parcelNumber"/>
              </Col>
              <Col md={3}>
                <CountySelect name="propertyCountyId" />
              </Col>
              <Col md={3}>
                <FormikInput labelText="City/Township"
                             name="propertyLocalUnit"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Owner"
                             name="ownerCompanyName"/>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormikInput labelText="Owner First"
                             name="ownerFirstName"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Owner Last"
                             name="ownerLastName"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Co-Owner First"
                             name="coOwnerFirstName"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Co-Owner Last"
                             name="coOwnerLastName"/>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormikInput labelText="Mail Street #"
                             name="mailingStreetNumber"/>
              </Col>
              <Col md={4}>
                <FormikInput labelText="Mail Street Name"
                             name="mailingStreetName"/>
              </Col>
              <Col md={4}>
                <FormikInput labelText="Mail City"
                             name="mailingCity"/>
              </Col>
              <Col md={2}>
                <StatesSelect name="mailingState"/>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormikInput labelText="Prop Street #"
                             name="propertyStreetNumber"/>
              </Col>
              <Col md={4}>
                <FormikInput labelText="Prop Street Name"
                             name="propertyStreetName"/>
              </Col>
              <Col md={4}>
                <FormikInput labelText="Prop City"
                             name="propertyCity"/>
              </Col>
              <Col md={2}>
                <FormikNumberInput labelText="PRE %"
                                   name="prePercent"
                                   maxLength={3}/>
              </Col>
            </Row>
          </SearchCard>
          {searchState.searching && <ProgressIndicator/>}
          <Card className={!searchState.searchPerformed || searchState.searching ? 'd-none' : 'mt-3'}>
            <CardHeader>Search Results</CardHeader>
            <ExemptionResultsTable exemptions={exemptions}/>
          </Card>
        </>)}
    </Formik>
  );
};

export default PriorYearsTab;
