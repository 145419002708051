import {useCallback, useMemo} from 'react';

import {AppBar, SOMLogo, User, UserProfile} from '@reasoncorp/kyber-js';

import {ssoApi} from '../api';
import config from '../config';

type Props = {
  currentUser?: User | null
  isAdmin: boolean
}

const AuditProAppBar = ({
                          currentUser,
                          isAdmin
                        }: Props) => {
  const handleProfileClick = useCallback(() => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  }, []);

  const handleSignOut = useCallback(async () => {
    await ssoApi.signOut();
  }, []);

  const renderUserProfile = useMemo(() => () => {
    return currentUser ? <UserProfile handleProfileClick={handleProfileClick}
                                      handleSignOut={handleSignOut}/> : undefined;
  }, [
    currentUser,
    handleSignOut,
    handleProfileClick
  ]);

  const routes = useMemo(() => ([
    {name: 'Search', to: '/', exact: true},
    {name: 'Clean', to: '/clean'},
    {name: 'Exports', to: '/exports', hide: !isAdmin},
    {name: 'Reports', to: '/reports', hide: !isAdmin}
  ]), [
    isAdmin
  ]);

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="ProSuite"
            appName="AuditPro"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderUserProfile={renderUserProfile}/>
  );
};

export default AuditProAppBar;
