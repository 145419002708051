import {useContext, useState} from 'react';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {AlertsContext, CustomTable, FormikInput, ProgressIndicator} from '@reasoncorp/kyber-js';

import {incomeTaxApi} from '../../api';
import * as messages from '../../messages';
import {incomeTaxSearchSchema} from '../../schema';
import {IncomeTax} from '../../types';
import {IncomeTaxFormFields} from '../../types/form';
import {IncomeTaxSearchRequest} from '../../types/request';
import {formatBoolean} from '../../util';
import {SearchCard} from '../shared';

const IncomeTaxTab = () => {
  const {showErrorAlert} = useContext(AlertsContext);
  const [searchState, setSearchState] = useState({searchPerformed: false, searching: false});
  const [incomeTaxes, setIncomeTaxes] = useState<IncomeTax[]>([]);

  const initialValues: IncomeTaxFormFields = {
    firstName: '',
    lastName: '',
    spouseFirstName: '',
    spouseLastName: '',
    streetNumber: '',
    streetName: '',
    city: '',
    searchErrors: ''
  };

  const tableProps = {
    headers: [
      {sortKey: 'lastName', title: 'Last Name'},
      {sortKey: 'firstName', title: 'First Name'},
      {sortKey: 'middleName', title: 'Middle Name'},
      {sortKey: 'spouseLastName', title: 'Spouse Last Name'},
      {sortKey: 'spouseFirstName', title: 'Spouse First Name'},
      {sortKey: 'spouseMiddleName', title: 'Spouse Middle Name'},
      {sortKey: 'address.streetNumber', title: 'Street #'},
      {sortKey: 'address.streetNameWithTypeAndDirections', title: 'Street Name'},
      {sortKey: 'address.unit', title: 'Unit', className: 'text-center'},
      {sortKey: 'address.city', title: 'City'},
      {sortKey: 'address.state', title: 'State'},
      {sortKey: 'address.zip', title: 'Zip'},
      {sortKey: 'filingStatus', title: 'File St.'},
      {sortKey: 'residence', title: 'Res'},
      {sortKey: 'renter', title: 'Renter'},
      {sortKey: 'year', title: 'Year'}
    ],
    chainSort: true,
    items: incomeTaxes,
    renderRow: (incomeTax: IncomeTax) => {
      return (
        <tr key={incomeTax.id}>
          <td>{incomeTax.lastName}</td>
          <td>{incomeTax.firstName}</td>
          <td>{incomeTax.middleName}</td>
          <td>{incomeTax.spouseLastName}</td>
          <td>{incomeTax.spouseFirstName}</td>
          <td>{incomeTax.spouseMiddleName}</td>
          <td>{incomeTax.address.streetNumber}</td>
          <td>{incomeTax.address.streetNameWithTypeAndDirections}</td>
          <td>{incomeTax.address.unit}</td>
          <td>{incomeTax.address.city}</td>
          <td>{incomeTax.address.state}</td>
          <td>{incomeTax.address.zip}</td>
          <td>{incomeTax.filingStatus}</td>
          <td>{incomeTax.residence}</td>
          <td>{formatBoolean(incomeTax.renter)}</td>
          <td>{incomeTax.year}</td>
        </tr>
      );
    },
    noResultsMessage: 'No Income Taxes match your search.'
  };

  const handleSearch = async (incomeTaxFormFields: IncomeTaxFormFields, formikHelpers: FormikHelpers<IncomeTaxFormFields>) => {
    try {
      const incomeTaxSearchRequest: IncomeTaxSearchRequest = {
        firstName: incomeTaxFormFields.firstName.trim(),
        lastName: incomeTaxFormFields.lastName.trim(),
        spouseFirstName: incomeTaxFormFields.spouseFirstName.trim(),
        spouseLastName: incomeTaxFormFields.spouseLastName.trim(),
        streetNumber: incomeTaxFormFields.streetNumber.trim(),
        streetName: incomeTaxFormFields.streetName.trim(),
        city: incomeTaxFormFields.city.trim()
      };
      formikHelpers.setValues(incomeTaxSearchRequest);
      setSearchState(searchState => ({...searchState, searching: true}));
      const incomeTaxesResults = await incomeTaxApi.search(incomeTaxSearchRequest);
      setIncomeTaxes(incomeTaxesResults as IncomeTax[]);
      setSearchState({searchPerformed: true, searching: false});
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setSearchState({searchPerformed: false, searching: false});
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleReset = () => {
    setIncomeTaxes([]);
    setSearchState(searchState => ({...searchState, searchPerformed: false}));
  };

  return (
    <Formik initialValues={initialValues}
            validateOnMount={true}
            onSubmit={handleSearch}
            validationSchema={incomeTaxSearchSchema}>
      {(formikProps) => (
        <>
          <SearchCard headerText="Search By"
                      errorMessage={formikProps.errors.searchErrors}
                      onReset={handleReset}>
            <Row>
              <Col>
                <FormikInput labelText="First Name"
                             name="firstName"
                             id="iFirstName"/>
              </Col>
              <Col>
                <FormikInput labelText="Last Name"
                             name="lastName"
                             id="iLastName"/>
              </Col>
              <Col>
                <FormikInput labelText="Spouse First Name"
                             name="spouseFirstName"/>
              </Col>
              <Col>
                <FormikInput labelText="Spouse Last Name"
                             name="spouseLastName"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput labelText="Street Number"
                             name="streetNumber"
                             id="iStreetNumber"/>
              </Col>
              <Col>
                <FormikInput labelText="Street Name"
                             name="streetName"
                             id="iStreetName"/>
              </Col>
              <Col>
                <FormikInput labelText="City"
                             name="city"
                             id="iCity"/>
              </Col>
            </Row>
          </SearchCard>
          {searchState.searching && <ProgressIndicator/>}
          <Card className={!searchState.searchPerformed || searchState.searching ? 'd-none' : 'mt-3'}>
            <CardHeader>Search Results</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </>
      )}
    </Formik>
  );
};

export default IncomeTaxTab;