import {useContext, useState} from 'react';
import {Button, Card, CardHeader, Col, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {AlertsContext, CustomTable, FormikInput, FormikPasswordInput, ProgressIndicator} from '@reasoncorp/kyber-js';

import {driversLicenseApi} from '../../api';
import * as messages from '../../messages';
import {DriversLicenseContext} from '../../contexts';
import {driversLicenseSearchSchema} from '../../schema';
import {DriversLicense} from '../../types';
import {DriversLicenseSearchRequest} from '../../types/request';
import {SearchCard} from '../shared';

const DriversLicenseTab = () => {
  const {showErrorAlert} = useContext(AlertsContext);
  const {password, setPassword, decryptedLicenses, setDecryptedLicenses} = useContext(DriversLicenseContext);
  const [searchState, setSearchState] = useState({searchPerformed: false, searching: false});
  const [licenses, setLicenses] = useState<DriversLicense[]>([]);

  const initialValues: DriversLicenseSearchRequest = {
    licenseNumber: '',
    password: password ? password : '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    city: '',
    unit: ''
  };

  const renderLicenseNumber = (id: number) => {
    return (
      decryptedLicenses[id] ? decryptedLicenses[id] :
        <Button color="link"
                onClick={() => handleViewLicenseNumber(id)}>
          View
        </Button>
    );
  };

  const tableProps = {
    headers: [
      {sortKey: 'licenseNumber', title: 'Number'},
      {sortKey: 'lastName', title: 'Last Name', className: 'text-center'},
      {sortKey: 'firstName', title: 'First Name', className: 'text-center'},
      {sortKey: 'middleName', title: 'Middle Name', className: 'text-center'},
      {sortKey: 'expirationYear', title: 'E. Year', className: 'text-center'},
      {sortKey: 'address.streetNumber', title: 'Street #', className: 'text-center'},
      {sortKey: 'address.streetNameWithTypeAndDirections', title: 'Street Name', className: 'text-center'},
      {sortKey: 'address.unit', title: 'Unit', className: 'text-center'},
      {sortKey: 'address.city', title: 'City', className: 'text-center'},
      {sortKey: 'address.zip', title: 'Zip', className: 'text-center'},
      {sortKey: 'personalOrVehicle', title: 'PID/VEH', className: 'text-center'},
      {sortKey: 'year', title: 'Year', className: 'text-center'}
    ],
    chainSort: true,
    items: licenses,
    renderRow: (driversLicense: DriversLicense) => {
      return (
        <tr key={driversLicense.id}>
          <td className="text-center align-middle">{renderLicenseNumber(driversLicense.id)}</td>
          <td className="text-center align-middle">{driversLicense.lastName}</td>
          <td className="text-center align-middle">{driversLicense.firstName}</td>
          <td className="text-center align-middle">{driversLicense.middleName}</td>
          <td className="text-center align-middle">{driversLicense.expirationYear}</td>
          <td className="text-center align-middle">{driversLicense.address.streetNumber}</td>
          <td className="text-center align-middle">{driversLicense.address.streetNameWithTypeAndDirections}</td>
          <td className="text-center align-middle">{driversLicense.address.unit}</td>
          <td className="text-center align-middle">{driversLicense.address.city}</td>
          <td className="text-center align-middle">{driversLicense.address.zip}</td>
          <td className="text-center align-middle">{driversLicense.personalOrVehicle}</td>
          <td className="text-center align-middle">{driversLicense.year}</td>
        </tr>
      );
    },
    noResultsMessage: 'No Drivers Licenses match your search.'
  };

  const handleSearch = async (driversLicenseSearchRequest: DriversLicenseSearchRequest, formikHelpers: FormikHelpers<DriversLicenseSearchRequest>) => {
    try {
      // Clear our previous decrypted licenses
      setDecryptedLicenses([]);
      const trimmedValues: DriversLicenseSearchRequest = {
        licenseNumber: driversLicenseSearchRequest.licenseNumber.trim(),
        password: driversLicenseSearchRequest.password.trim(),
        firstName: driversLicenseSearchRequest.firstName.trim(),
        lastName: driversLicenseSearchRequest.lastName.trim(),
        streetNumber: driversLicenseSearchRequest.streetNumber.trim(),
        streetName: driversLicenseSearchRequest.streetName.trim(),
        city: driversLicenseSearchRequest.city.trim(),
        unit: driversLicenseSearchRequest.unit.trim()
      };
      formikHelpers.setValues(trimmedValues);
      setSearchState(searchState => ({...searchState, searching: true}));
      const driversLicensesResults = await driversLicenseApi.search(trimmedValues);
      setLicenses(driversLicensesResults);
      if (driversLicenseSearchRequest.password && password !== driversLicenseSearchRequest.password) {
        setPassword(driversLicenseSearchRequest.password);
        // restores values after render caused by setPassword
        setTimeout(() => formikHelpers.setValues(trimmedValues), 100);
      }
      setSearchState({searchPerformed: true, searching: false});
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setSearchState({searchPerformed: false, searching: false});
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleReset = () => {
    setLicenses([]);
    setSearchState(searchState => ({...searchState, searchPerformed: false}));
  };

  const handleViewLicenseNumber = async (id: number) => {
    try {
      const passwordValue = !password ? window.prompt('Please enter your password') : password;
      const licenseNumber = await driversLicenseApi.decrypt(id, passwordValue as string);
      setDecryptedLicenses({...decryptedLicenses, [id]: licenseNumber});
      setPassword(passwordValue as string);
    } catch (e) {
      showErrorAlert(messages.DRIVER_LICENSE_VIEW_FAILURE);
    }
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={driversLicenseSearchSchema}
            enableReinitialize={true}
            onSubmit={handleSearch}>
      {() => (
        <>
          <SearchCard headerText="Search Driver License"
                      onReset={handleReset}>
            <Row>
              <Col md={3}>
                <FormikInput labelText="Driver License Number"
                             name="licenseNumber"/>
              </Col>
              <Col md={3}>
                <FormikPasswordInput labelText="Password"
                                     name="password"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="First Name"
                             name="firstName"
                             id="dlFirstName"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Last Name"
                             name="lastName"
                             id="dlLastName"/>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormikInput labelText="Street Number"
                             name="streetNumber"
                             id="dlStreetNumber"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Street Name"
                             name="streetName"
                             id="dlStreetName"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="City"
                             name="city"
                             id="dlCity"/>
              </Col>
              <Col md={3}>
                <FormikInput labelText="Unit"
                             name="unit"
                             id="dlUnit"/>
              </Col>
            </Row>
          </SearchCard>
          {searchState.searching && <ProgressIndicator/>}
          <Card className={!searchState.searchPerformed || searchState.searching ? 'd-none' : 'mt-3'}>
            <CardHeader>Drivers License</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </>)}
    </Formik>
  );
};

export default DriversLicenseTab;

