import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Formik} from 'formik';

import {
  BreadcrumbsNav,
  ExportBatchLite,
  ExportBatchReport,
  FormikSelect,
  ProgressIndicator,
  ProgressModal,
  useAlerts
} from '@reasoncorp/kyber-js';

import {reportApi} from '../../api';
import * as messages from '../../messages';
import {ExportSummaryTable, ExportTable} from '../../components/report';
import {formatDateTime} from '../../util';

const ExportReport = () => {
  const navigate = useNavigate();
  const {batchId, year} = useParams<{batchId: string, year: string}>();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [selectedBatch, setSelectedBatch] = useState<ExportBatchLite | undefined>(undefined);
  const [exportBatches, setExportBatches] = useState<ExportBatchLite[]>([]);
  const [exportBatchReport, setExportBatchReport] = useState<ExportBatchReport | undefined>(undefined);
  const [selectedBatchId, setSelectedBatchId] = useState<number>(Number(batchId));
  const [loadingState, setLoadingState] = useState({
    initialLoading: true,
    loadError: false,
    loadingBatch: true,
    exportingReport: false
  });

  // This is needed because the button will try to expand to height: 100%
  const buttonStyle = useMemo(() => ({
    height: '38px'
  }), []);

  useEffect(() => {
    const loadExportBatches = async () => {
      try {
        const exportBatches = await reportApi.findExportBatches(Number(year));
        setExportBatches(exportBatches);
        const selectedBatchId = Number(batchId ? batchId : exportBatches[0].batchId);
        setSelectedBatchId(selectedBatchId);
        setSelectedBatch(exportBatches.filter(exportBatch => exportBatch.batchId === selectedBatchId)?.[0] ?? exportBatches[0]);
        setLoadingState({
          loadError: false,
          initialLoading: false,
          loadingBatch: false,
          exportingReport: false
        });
      } catch (error) {
        setLoadingState({
          loadError: true,
          initialLoading: false,
          loadingBatch: false,
          exportingReport: false
        });
        showErrorAlert(messages.REPORT_LOAD_FAILURE);
      }
    };

    void loadExportBatches();
  }, [batchId, year, showErrorAlert]);

  useEffect(() => {
    const loadExportBatch = async () => {
      if (selectedBatchId) {
        try {
          setLoadingState({
            loadError: false,
            loadingBatch: true,
            initialLoading: false,
            exportingReport: false
          });
          const exportBatchReport = await reportApi.findExportBatch(selectedBatchId);
          setExportBatchReport(exportBatchReport);
          setLoadingState({
            loadError: false,
            loadingBatch: false,
            initialLoading: false,
            exportingReport: false
          });
        } catch (error) {
          setLoadingState({
            loadError: true,
            loadingBatch: false,
            initialLoading: false,
            exportingReport: false
          });
          showErrorAlert(messages.REPORT_LOAD_FAILURE);
        }
      }
    };
    void loadExportBatch();
  }, [selectedBatchId, showErrorAlert]);

  const handleItemSelect = useCallback((batchId: number) => {
    navigate(`/reports/export/${year}/${batchId}`);
  }, [
    navigate,
    year
  ]);

  const handleReportDownload = useCallback(async () => {
    try {
      setLoadingState({
        loadError: false,
        loadingBatch: false,
        initialLoading: false,
        exportingReport: true
      });
      await reportApi.downloadExportBatchReport(selectedBatchId);
      showSuccessAlert(messages.REPORT_DOWNLOAD_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_DOWNLOAD_FAILED);
    } finally {
      setLoadingState({
        loadError: false,
        loadingBatch: false,
        initialLoading: false,
        exportingReport: false
      });
    }
  }, [
    selectedBatchId,
    showErrorAlert,
    showSuccessAlert
  ]);

  return (
    <Container fluid>
      {loadingState.loadingBatch && <ProgressIndicator className="mb-4"/>}
      {!loadingState.loadingBatch && !loadingState.loadingBatch && !loadingState.loadError && selectedBatch && <>
        <BreadcrumbsNav breadcrumbs={[
          {text: 'Reports', route: '/reports', icon: 'home' as const},
          {text: `${year} Export Report`, active: true}
        ]}/>
        <Row className="mt-2">
          <Col className="col-3">
            <Formik initialValues={{year}}
                    onSubmit={async () => null}>
              {(_) => (
                <Form autoComplete="off"
                      className="mb-0">
                  <FormikSelect name="year"
                                onChange={(e) => handleItemSelect(Number(e.target.value))}
                                labelText="Batch">
                    {exportBatches.map(exportBatch =>
                      <option value={exportBatch.batchId}>{formatDateTime(exportBatch.createdAt)}</option>)}
                  </FormikSelect>
                </Form>
              )}
            </Formik>
          </Col>
          <Col className="col-9 d-flex justify-content-end">
            <Button color="primary"
                    style={buttonStyle}
                    onClick={handleReportDownload}
                    disabled={loadingState.loadingBatch || loadingState.initialLoading || loadingState.loadError}
                    className="align-middle mt-3">
              Download Report
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs="12" md="12">
            {loadingState.loadingBatch && <ProgressIndicator className="mb-4"/>}
            {!loadingState.loadingBatch && !loadingState.loadingBatch && exportBatchReport &&
              <>
                <Row className="mb-4">
                  <Col>
                    <ExportSummaryTable selectedBatch={selectedBatch}
                                        exportSummaries={exportBatchReport.summaries}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ExportTable items={exportBatchReport.items}/>
                  </Col>
                </Row>
              </>
            }
          </Col>
        </Row>
      </>}
      <ProgressModal isOpen={loadingState.exportingReport}
                     title="Generating Export Report"
                     content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
    </Container>
  );
};

export default ExportReport;