import {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Badge, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {AlertsContext, ProgressIndicator, useUserContext} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {useAuditProAppContext} from '../hooks';
import {isExemptionInCurrentYears} from '../util';
import {auditHistoryApi, collectionsApi, exemptionApi, ownerApi, propertyApi, scanApi} from '../api';
import {AuditHistory, Exemption, MajorReason, Owner, Property, QueryCode} from '../types';
import {AuditRequest, QuestionnaireRequest} from '../types/request';
import {OwnerCard} from '../components/shared';
import {ParcelPanel, PreCard, PropertyCard, SaleCard} from '../components/exemption';
import {
  AdminTab,
  AuditTab,
  DriversLicenseTab,
  HistoryTab,
  IncomeTaxTab,
  PriorYearsTab,
  ScansTab,
  Tab,
  TabPanel
} from '../components/exemptionTabs';
import config from '../config';

const ExemptionDetails = () => {
  const id = Number(useParams<{id: string}>().id);
  const {showSuccessAlert, showErrorAlert} = useContext(AlertsContext);
  const {configuration} = useAuditProAppContext();
  const {permissions} = useUserContext();
  const [loadingState, setLoadingState] = useState({loadError: false, loading: false});
  const [owner, setOwner] = useState<Owner | undefined>(undefined);
  const [editingOwner, setEditingOwner] = useState(false);
  const [property, setProperty] = useState<Property | undefined>(undefined);
  const [editingProperty, setEditingProperty] = useState(false);
  const [exemption, setExemption] = useState<Exemption | undefined>(undefined);
  const [auditHistories, setAuditHistories] = useState<AuditHistory[]>([]);
  const [queryCodes, setQueryCodes] = useState<QueryCode[]>([]);
  const [majorReasons, setMajorReasons] = useState<MajorReason[]>([]);
  const [activeTab, setActiveTab] = useState('Audit');
  const editable = isExemptionInCurrentYears(exemption, configuration.currentYears);

  useEffect(() => {
    const loadExemption = async () => {
      if (config.envName !== 'prod') {
        document.title = `${config.envName.toUpperCase()} - ${messages.APP_TITLE_PARCEL_VIEW}`;
      } else {
        document.title = messages.APP_TITLE_PARCEL_VIEW;
      }

      try {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: true}));
        const [exemption, auditHistories, queryCodes, majorReasons] = await Promise.all([
          exemptionApi.find(id),
          auditHistoryApi.findByExemptionId(id),
          collectionsApi.queryCodes(),
          collectionsApi.majorReasons()
        ]);
        setExemption(exemption);
        setOwner({...exemption.owner});
        setProperty({...exemption.property});
        setAuditHistories(auditHistories);
        setQueryCodes(queryCodes);
        setMajorReasons(majorReasons);
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loadError: true}));
      } finally {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: false}));
      }
    };
    void loadExemption();
  }, [id, showErrorAlert]);

  const handleEditOwnerCancel = useCallback(() => setEditingOwner(false), []);

  const toggleEditOwner = useCallback(() => setEditingOwner(editingOwner => !editingOwner), []);

  const handleUpdateOwner = useCallback(async (ownerRequest: Owner) => {
      try {
        const updatedOwner = await ownerApi.update(exemption?.owner?.id ?? 0, ownerRequest);
        showSuccessAlert(messages.OWNER_SAVE_SUCCESSFUL);
        setEditingOwner(false);
        setOwner(updatedOwner);
      } catch (e) {
        showErrorAlert(messages.OWNER_SAVE_FAILURE);
      }
    },
    [exemption, showSuccessAlert, showErrorAlert]
  );

  const toggleEditProperty = useCallback(() => setEditingProperty(editingProperty => !editingProperty), []);

  const handleEditPropertyCancel = useCallback(() => setEditingProperty(false), []);

  const handleUpdateProperty = useCallback(async (propertyRequest: Property) => {
      try {
        const updatedProperty = await propertyApi.update(exemption?.property?.id ?? 0, propertyRequest);
        showSuccessAlert(messages.PROPERTY_SAVE_SUCCESSFUL);
        setEditingProperty(false);
        setProperty(updatedProperty);
      } catch (e) {
        showErrorAlert(messages.PROPERTY_SAVE_FAILURE);
      }
    },
    [exemption, showSuccessAlert, showErrorAlert]
  );

  const handleSaveAudit = useCallback(async (auditRequest: AuditRequest, formikHelpers: FormikHelpers<AuditRequest>) => {
      try {
        formikHelpers.setSubmitting(true);
        await auditHistoryApi.create(id, auditRequest);
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
        showSuccessAlert(messages.AUDIT_SAVE_SUCCESSFUL);
        const [exemption, auditHistories] = await Promise.all([
          exemptionApi.find(id),
          auditHistoryApi.findByExemptionId(id)
        ]);
        setExemption(exemption);
        setAuditHistories(auditHistories);
      } catch (e) {
        formikHelpers.setSubmitting(false);
        showErrorAlert(messages.AUDIT_SAVE_FAILURE);
      }
    },
    [id, showSuccessAlert, showErrorAlert]
  );

  const handleScanDownload = useCallback(async () => {
      try {
        await scanApi.download(exemption?.year || -1, exemption?.reasonId || '');
      } catch (error) {
        showErrorAlert(messages.SCAN_DOWNLOAD_FAILURE);
      }
    },
    [exemption, showErrorAlert]
  );

  const renderTabWithCount = useCallback((length: number, text: string) => {
      return (
        <div className="d-flex">
          {length > 0 && <Badge color="danger" className="mr-1 align-self-center">{length}</Badge>}
          <span>{text}</span>
        </div>
      );
    },
    []
  );

  const handleSaveQuestionnaire = useCallback(async (questionnaireRequest: QuestionnaireRequest) => {
      try {
        await exemptionApi.createQuestionnaire(id, questionnaireRequest);
        const exemption = await exemptionApi.find(id);
        setExemption(exemption);
        showSuccessAlert(messages.QUESTIONNAIRE_SAVE_SUCCESSFUL);
      } catch (e) {
        showErrorAlert(messages.QUESTIONNAIRE_SAVE_FAILURE);
      }
    },
    [id, showSuccessAlert, showErrorAlert]
  );

  const handleSaveDuplicate = useCallback(async () => {
      try {
        await exemptionApi.createDuplicate(id);
        setExemption({...exemption, hasSecondDenial: true} as Exemption);
        showSuccessAlert(messages.DUPLICATE_SAVE_SUCCESSFUL);
      } catch (e) {
        showErrorAlert(messages.DUPLICATE_SAVE_FAILURE);
      }
    },
    [id, exemption, showSuccessAlert, showErrorAlert]
  );

  const handleDeleteAuditHistory = useCallback(async (auditHistoryId: number) => {
      try {
        await auditHistoryApi.remove(id, auditHistoryId);
        const [exemption, auditHistories] = await Promise.all([
          exemptionApi.find(id),
          auditHistoryApi.findByExemptionId(id)
        ]);
        setExemption(exemption);
        setAuditHistories(auditHistories);
        showSuccessAlert(messages.HISTORY_DELETE_SUCCESSFUL);
      } catch (e) {
        showErrorAlert(messages.HISTORY_DELETE_FAILURE);
      }
    },
    [id, showSuccessAlert, showErrorAlert]
  );

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {exemption && !loadingState.loadError && !loadingState.loading &&
        <>
          <Row>
            <Col className="col-sm-12">
              <ParcelPanel exemption={exemption}/>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6 col-sm-12">
              {owner &&
                <OwnerCard owner={owner}
                           toggleEdit={toggleEditOwner}
                           onCancel={handleEditOwnerCancel}
                           onUpdate={handleUpdateOwner}
                           isEditing={editingOwner}/>
              }
            </Col>
            <Col className="col-md-6 col-sm-12">
              {property &&
                <PropertyCard className="mb-3"
                              property={property}
                              onCancel={handleEditPropertyCancel}
                              toggleEdit={toggleEditProperty}
                              isEditing={editingProperty}
                              onUpdate={handleUpdateProperty}/>
              }
              <PreCard className="mb-3" exemption={exemption}/>
              <SaleCard className="mb-3" sale={exemption.sale}/>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ul role="tablist" className="bg-secondary text-white p-0 tab-nav">
                <Tab name="Audit"
                     content="Audit"
                     active={activeTab === 'Audit'}
                     onClick={setActiveTab}/>
                <Tab name="History"
                     content={renderTabWithCount(auditHistories.length, 'History')}
                     active={activeTab === 'History'}
                     onClick={setActiveTab}/>
                <Tab name="PriorYears"
                     content="Prior Years"
                     active={activeTab === 'PriorYears'}
                     onClick={setActiveTab}/>
                <Tab name="DriverLicenseSearch"
                     content="Driver License Search"
                     active={activeTab === 'DriverLicenseSearch'}
                     onClick={setActiveTab}/>
                <Tab name="IncomeTaxSearch"
                     content="Income Tax Search"
                     active={activeTab === 'IncomeTaxSearch'}
                     onClick={setActiveTab}/>
                <Tab name="Scans"
                     content={renderTabWithCount(exemption.hasScan ? 1 : 0, 'Scans')}
                     active={activeTab === 'Scans'}
                     onClick={setActiveTab}/>
                {permissions.isAdmin &&
                  <Tab name="Admin"
                       content="Admin"
                       active={activeTab === 'Admin'}
                       onClick={setActiveTab}/>
                }
              </ul>
              <TabPanel name="Audit"
                        active={activeTab === 'Audit'}>
                <AuditTab exemption={exemption}
                          editable={editable}
                          onSave={handleSaveAudit}
                          majorReasons={majorReasons}/>
              </TabPanel>
              <TabPanel name="History"
                        active={activeTab === 'History'}>
                <HistoryTab year={exemption.year}
                            deletable={editable}
                            history={auditHistories}
                            onDelete={handleDeleteAuditHistory}/>
              </TabPanel>
              <TabPanel name="PriorYears"
                        active={activeTab === 'PriorYears'}>
                <PriorYearsTab/>
              </TabPanel>
              <TabPanel name="DriverLicenseSearch"
                        active={activeTab === 'DriverLicenseSearch'}>
                <DriversLicenseTab/>
              </TabPanel>
              <TabPanel name="IncomeTaxSearch"
                        active={activeTab === 'IncomeTaxSearch'}>
                <IncomeTaxTab/>
              </TabPanel>
              <TabPanel name="Scans"
                        active={activeTab === 'Scans'}>
                <ScansTab exemption={exemption}
                          onDownload={handleScanDownload}/>
              </TabPanel>
              <TabPanel name="Admin"
                        active={activeTab === 'Admin'}>
                <AdminTab questionnaire={exemption.questionnaire}
                          queryCodes={queryCodes}
                          onSaveQuestionnaire={handleSaveQuestionnaire}
                          onSaveDuplicate={handleSaveDuplicate}
                          hasSecondDenial={exemption.hasSecondDenial}/>
              </TabPanel>
            </Col>
          </Row>
        </>
      }
    </Container>
  );
};

export default ExemptionDetails;
