import {SyntheticEvent} from 'react';

// Open and focus to a url.
const openAndFocus = (event: SyntheticEvent, url: string, target: string) => {
  target = target || '_blank';
  event.preventDefault();
  event.nativeEvent.stopImmediatePropagation();
  let tabs = [];
  tabs.push(window.open(url, target));
  tabs?.[0]?.focus();
  return false;
};

export default openAndFocus;
