import {ReactNode, useEffect, useState} from 'react';

import DriversLicenseContext from './DriversLicenseContext';
import {DecryptedLicenses} from '../types';

// Password expires in 4 hours
const PASSWORD_EXPIRES_TIMEOUT = 4 * 60 * 60 * 1000;
// Check password expires every minute
const PASSWORD_EXPIRES_INTERVAL = 60 * 1000;

type Props = {
  children: ReactNode
}
const DriversLicenseProvider = ({children}: Props) => {
  const [decryptedLicenses, setDecryptedLicenses] = useState<DecryptedLicenses>({});
  const [password, setPasswordValue] = useState<string | null>(localStorage.getItem('password'));

  useEffect(() => {
    const interval = setInterval(() => {
      const passwordExpiresAt = localStorage.getItem('passwordExpiresAt');
      if (passwordExpiresAt) {
        const passwordExpiresAtDate = new Date(passwordExpiresAt);
        if (new Date() > passwordExpiresAtDate) {
          setPasswordValue(null);
          setDecryptedLicenses({});
          localStorage.setItem('password', '');
        }
      }
    }, PASSWORD_EXPIRES_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const setPassword = (password: string) => {
    const passwordExpiresAt = new Date(new Date().getTime() + PASSWORD_EXPIRES_TIMEOUT);
    localStorage.setItem('passwordExpiresAt', passwordExpiresAt.toISOString());
    localStorage.setItem('password', password);
    setPasswordValue(password);
  };

  return <DriversLicenseContext.Provider value={{password, setPassword, decryptedLicenses, setDecryptedLicenses}}>
    {children}
  </DriversLicenseContext.Provider>;
};

export default DriversLicenseProvider;