import {Card, CardBody, CardHeader, Table} from 'reactstrap';

import {formatDate} from '../../util';
import {Exemption} from '../../types';

type Props = {
  exemption: Exemption
  className?: string
}

const PreCard = ({
                   exemption,
                   className
                 }: Props) => {
  return (
    <Card className={className}>
      <CardHeader>PRE Information</CardHeader>
      <CardBody>
        <Table responsive size="sm" striped>
          <tbody>
            <tr>
              <th scope="row" className="border-0">PRE %</th>
              <th headers="PRE percentage" className="border-0 font-weight-normal text-dark">{Math.round(exemption.prePercent)}%</th>
              <th scope="row" className="border-0">PRE Date</th>
              <th headers="PRE date" className="border-0 font-weight-normal text-dark">{formatDate(exemption.preOn)}</th>
            </tr>
            <tr>
              <th scope="row">Exempt Flag</th>
              <th headers="exempt flag" className="font-weight-normal text-dark">{exemption.agFlag}</th>
              <th scope="row">Rescinded Date</th>
              <th headers="rescinded date" className="font-weight-normal text-dark">{formatDate(exemption.rescindedOn)}</th>
            </tr>
            <tr>
              <th scope="row">Adjacent Parcel</th>
              <th headers="adjacent parcel" className="font-weight-normal text-dark">{exemption.adjacentParcel}</th>
              <th scope="row">Denial Date</th>
              <th headers="denial date" className="font-weight-normal text-dark">{formatDate(exemption.deniedOn)}</th>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default PreCard;