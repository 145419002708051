import {auditProApi} from './apiUtils';
import {DriversLicense} from '../types';
import {DriversLicenseSearchRequest} from '../types/request';

export const search = (driversLicenseSearchRequest:
                         DriversLicenseSearchRequest): Promise<DriversLicense[]> => auditProApi.postWithJsonResponse(
  `/drivers-licenses`,
  {
    body: JSON.stringify(driversLicenseSearchRequest)
  }
);

export const decrypt = (id: number, password: string): Promise<string> => auditProApi.postWithTextResponse(
  `/drivers-licenses/${id}/decrypt`,
  {
    body: JSON.stringify({password})
  }
);