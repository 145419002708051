import {createContext} from 'react';

import {DriversLicenseContextInterface} from '../types';

const DriversLicenseContext = createContext<DriversLicenseContextInterface>({
  password: null,
  setPassword: () => null,
  setDecryptedLicenses: () => null,
  decryptedLicenses: {}
});

export default DriversLicenseContext;