import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object({
  queryCode: Yup.object({
    id: Yup.number().required(messages.REQUIRED)
  }),
  letterOn: Yup.date()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED)
});