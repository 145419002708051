import {memo, ReactNode} from 'react';

type Props = {
  name: string
  active: boolean
  children: ReactNode
};

const TabPanel = ({
                    name,
                    active,
                    children
                  }: Props) => {
  return (
    <div className={!active ? 'd-none' : ''}
         role="tabpanel"
         aria-controls="audit"
         tabIndex={0}
         aria-labelledby={`tabs-${name}`}
         aria-hidden={!active}>
      {children}
    </div>
  );
};

export default memo(TabPanel);