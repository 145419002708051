import * as Yup from 'yup';

import * as messages from '../messages';
import {isNotNullOrBlank} from '../util';

export default Yup.object({
  year: Yup.number(),
  parcelNumber: Yup.string(),
  propertyCounty: Yup.string(),
  propertyLocalUnit: Yup.string(),
  ownerCompanyName: Yup.string(),
  ownerFirstName: Yup.string(),
  ownerLastName: Yup.string(),
  coOwnerFirstName: Yup.string(),
  coOwnerLastName: Yup.string(),
  mailingStreetNumber: Yup.string(),
  mailingStreetName: Yup.string(),
  mailingCity: Yup.string(),
  mailingState: Yup.string(),
  propertyStreetNumber: Yup.string(),
  propertyStreetName: Yup.string(),
  propertyCity: Yup.string(),
  prePercent: Yup.number().nullable()
}).test('validateEmptySearch', '', function (this: any, values: any) {
  const isValid = [
    values.parcelNumber,
    values.propertyCounty,
    values.propertyLocalUnit,
    values.ownerCompanyName,
    values.ownerFirstName,
    values.ownerLastName,
    values.coOwnerFirstName,
    values.coOwnerLastName,
    values.mailingStreetName,
    values.mailingStreetNumber,
    values.mailingCity,
    values.mailingState,
    values.propertyStreetName,
    values.propertyStreetNumber,
    values.propertyCity,
    values.prePercent].some(isNotNullOrBlank);
  return isValid || this.createError({path: 'searchErrors', message: messages.ONE_FIELD_REQUIRED});
});