import * as Yup from 'yup';

import * as messages from '../messages';
import {isNotNullOrBlank} from '../util';

export default Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  spouseFirstName: Yup.string(),
  spouseLastName: Yup.string(),
  streetNumber: Yup.string(),
  streetName: Yup.string(),
  city: Yup.string()
}).test('validateEmptySearch', '', function (this: any, values: any) {
  const isValid = [values.firstName, values.lastName, values.spouseFirstName, values.spouseLastName,
    values.streetName, values.streetNumber, values.city].some(isNotNullOrBlank);
  return isValid || this.createError({path: 'searchErrors', message: messages.ONE_FIELD_REQUIRED});
});
