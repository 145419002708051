import {useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, Footer, NotFoundV2, SkipNav, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {configurationApi} from './api';
import * as messages from './messages';
import {AuditProAppBar} from './components';
import {useAuditProAppContext} from './hooks';
import {Clean, CleanCounty, CleanOwner, Dashboard, ExemptionDetails, Exports} from './containers';
import {CleanedRecordsReport, ExportReport, Reports, ValidExemptionsReport} from './containers/reports';

const App = () => {
  const {showErrorAlert} = useAlerts();
  const {currentUser, loadingUser, permissions} = useUserContext();
  const {setConfiguration} = useAuditProAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const loadConfiguration = async () => {
        try {
          if (currentUser) {
            const configuration = await configurationApi.get();
            setConfiguration(configuration);
            setLoading(false);
          }
        } catch (error) {
          showErrorAlert(messages.API_FAILURE);
        }
      };
      void loadConfiguration();
    },
    [currentUser, setConfiguration, showErrorAlert]
  );

  return (
    <>
      <SkipNav/>
      <AuditProAppBar currentUser={currentUser}
                      isAdmin={permissions.isAdmin}/>
      {!loading && !loadingUser &&
        <main role="main">
          <Routes>
            <Route path="/" element={<Navigate replace to="/exemptions"/>}/>
            <Route path="/exemptions" element={<Dashboard/>}/>
            <Route path="/exemptions/:id" element={<ExemptionDetails/>}/>
            <Route path="/clean" element={<Clean/>}/>
            <Route path="/clean/:countyId" element={<CleanCounty/>}></Route>
            <Route path="/clean/:countyId/:localUnit" element={<CleanOwner/>}/>
            {permissions.isAdmin && <>
              <Route path="/exports" element={<Exports/>}/>
              <Route path="/reports" element={<Reports/>}/>
              <Route path="/reports/cleaned-records/:year" element={<CleanedRecordsReport/>}/>
              <Route path="/reports/cleaned-records/" element={<CleanedRecordsReport/>}/>
              <Route path="/reports/export/:year/:batchId" element={<ExportReport/>}/>
              <Route path="/reports/export/:year" element={<ExportReport/>}/>
              <Route path="/reports/export" element={<ExportReport/>}/>
              <Route path="/reports/valid-records/:year" element={<ValidExemptionsReport/>}/>
            </>}
            <Route path="*" element={<NotFoundV2/>}/>
          </Routes>
        </main>
      }
      <Footer brand="State of Michigan"
              brandLink="https://michigan.gov"
              footerLinks={[
                {name: 'MI.gov', to: 'https://michigan.gov'},
                {name: 'AuditPro Home', to: '/'},
                {name: 'Contact', to: 'https://www.michigan.gov/treasury/0,4679,7-121--8483--,00.html'},
                {name: 'Policies', to: 'https://www.michigan.gov/policies'}
              ]}
      />
      <Alerts/>
    </>
  );
};

export default App;
