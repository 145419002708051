import * as Yup from 'yup';

export default Yup.object({
  licenseNumber: Yup.string(),
  password: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  streetNumber: Yup.string(),
  streetName: Yup.string(),
  city: Yup.string(),
  unit: Yup.string()
});