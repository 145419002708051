import {ExportBatchLite, ExportBatchReport} from '@reasoncorp/kyber-js';

import {auditProApi} from './apiUtils';
import {CleanedRecordReport, ValidExemptionReportDto} from '../types/report';

export const findCleanedRecords = (year: number): Promise<CleanedRecordReport> => auditProApi.getWithJsonResponse(
  `/reports/cleaned-records/${year}`
);

export const downloadCleanedRecordsReport = (year: number) => auditProApi.getWithBlobResponse(
  `/reports/cleaned-records/${year}/download`
);

export const findExportBatch = (batchId: number): Promise<ExportBatchReport> => auditProApi.getWithJsonResponse(
  `/reports/exports/batches/${batchId}`
);

export const findExportBatches = (year: number): Promise<ExportBatchLite[]> => auditProApi.getWithJsonResponse(
  `/reports/exports/batches?year=${year}`
);

export const downloadExportBatchReport = (batchId: number) => auditProApi.getWithBlobResponse(
  `/reports/exports/batches/${batchId}/download`
);

export const findValidExemptionsReport = (year: number): Promise<ValidExemptionReportDto[]> => auditProApi.getWithJsonResponse(
  `/reports/valid-exemptions/${year}`
);

export const downloadValidExemptionsReport = (year: number) => auditProApi.getWithBlobResponse(
  `/reports/valid-exemptions/${year}/download`
);

export const downloadLetterSentReport = (year: number) => auditProApi.getWithBlobResponse(
  `/reports/letter-sent/${year}/download`
);

export const downloadQuestionnaireReport = (year: number) => auditProApi.getWithBlobResponse(
  `/reports/questionnaire/${year}/download`
)

export const downloadNonResponseReport = (letterSentOn: string) => auditProApi.getWithBlobResponse(
  `/reports/non-response/${letterSentOn}/download`
)
