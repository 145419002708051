import {Button, Col, Row} from 'reactstrap';

type Props = {
  onClean: () => void,
  onSkip: () => void,
  onUpdate: () => void,
  onCancel: () => void
}

const CleanButtons = ({
                        onClean,
                        onSkip,
                        onUpdate,
                        onCancel
                      }: Props) => {
  return <Row>
    <Col className="justify-content-end d-flex">
      <Button className="mr-2"
              color="success"
              onClick={onClean}>
        Clean
      </Button>
      <Button className="mr-2"
              color="success"
              onClick={onSkip}>
        Pass
      </Button>
      <Button className="mr-2"
              color="success"
              onClick={onUpdate}>
        Save
      </Button>
      <Button color="secondary"
              onClick={onCancel}>
        Cancel
      </Button>
    </Col>
  </Row>;
};

export default CleanButtons;