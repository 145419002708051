import {FormikSelect} from '@reasoncorp/kyber-js';

import {useAuditProAppContext} from '../../hooks';

type Props = {
  name: string
}

const CountySelect = ({
                        name
                      }: Props) => {
  const {configuration} = useAuditProAppContext();

  return (
    <FormikSelect name={name}
                  labelText="County">
      <option value="">Select</option>
      {configuration.counties.map((county) => {
        return <option value={county.id} key={county.id}>
          {county.displayName}
        </option>;
      })}
    </FormikSelect>
  );
};

export default CountySelect;
