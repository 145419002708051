enum AuditActivityCode {
  TAXPAYER_CALL = 1,
  QUESTIONNAIRE_RECEIVED = 2,
  COMMENTS = 3,
  UNDELIVERABLE = 5,
  MAIL_RETURNED = 6,
  NO_QUESTIONNAIRE_RECEIVED = 7,
  FINAL_REVIEW = 8
}

export default AuditActivityCode;